import { inject, Injectable } from "@angular/core";
import { LiveStreamsApi, LiveScheduleListsApi, ApiLiveStream, ApiLiveScheduleList } from "@tytapp/api";
import { Cache } from "@tytapp/common";
import { Subject, Observable } from "rxjs";
import { PagedArray } from "@tytapp/common";
import { LoggerService } from "@tytapp/common";
import { UserService } from "@tytapp/user";
import { isServerSide } from '@tytapp/environment-utils';
import { BillingService } from '@tytapp/billing';

@Injectable()
export class LiveStreamsService {
    private liveStreamsApi = inject(LiveStreamsApi);
    private liveSchedulesApi = inject(LiveScheduleListsApi);
    private logger = inject(LoggerService);
    private userService = inject(UserService);
    private billing = inject(BillingService);

    private cache: Cache<ApiLiveStream> = Cache.shared<ApiLiveStream>('live-streams', 1000 * 60, 10);
    private listCache = Cache.shared<ApiLiveStream[]>('live-streams-list', 1000 * 60, 5);
    private scheduleCache = Cache.shared<ApiLiveScheduleList[]>('live-schedule', 1000 * 60 * 5, 2);
    private indicatorCache: Cache<boolean> = Cache.shared<boolean>('live-indicator', 1000 * 60, 1);

    activeStreams: ApiLiveStream[] = [];
    private _streamChanged: Subject<ApiLiveStream> = new Subject<ApiLiveStream>();

    get streamChanged(): Observable<ApiLiveStream> {
        return this._streamChanged;
    }

    lastRefreshed: number = 0;
    refreshTime: number = 1000 * 60;

    async getLiveIndicator(): Promise<boolean> {
        try {
            return await this.indicatorCache.fetch('default', async () => {
                return <boolean>await this.liveStreamsApi.indicator().toPromise();
            });
        } catch (e) {
            this.logger.error(`Failed to fetch live indicator status, returning false: ${e}`);
            return false;
        }
    }

    async getPreferredActiveLiveStream(prefer: 'auto' | 'members-only' | 'public' = 'auto') {

        let streams = await this.active();
        let entitled = await this.billing.isEntitled();

        streams = streams.sort((a, b) => {
            if (b.active !== a.active)
                return Number(b.active) - Number(a.active);
            else
                return (new Date(b.created_at).getTime() - (new Date(a.created_at).getTime()));
        });

        let premiumStream = streams.find(x => x.premium);
        let publicStream = streams.find(x => !x.premium);
        let stream = null;

        if (entitled) {
            stream = premiumStream || publicStream;
        } else {
            stream = publicStream || premiumStream;
        }

        if (prefer === 'members-only') {
            stream = premiumStream;
        } else if (prefer === 'public') {
            stream = publicStream;
        }

        return stream;
    }

    async getSchedule(offsetInHours?: number): Promise<ApiLiveScheduleList[]> {
        let date = new Date();
        if (offsetInHours === undefined)
            offsetInHours = date.getTimezoneOffset() / 60;

        if (isServerSide()) {
            offsetInHours = 5; // EST
        }

        return await this.scheduleCache.fetch(
            `default-offset-${offsetInHours}`,
            async () => await this.liveSchedulesApi.all({
                params: {
                    timezone_offset: -(offsetInHours)
                }
            }).toPromise() as PagedArray<ApiLiveScheduleList>
        );
    }

    async active(refresh: boolean = false) {

        if (this.lastRefreshed + this.refreshTime < Date.now())
            refresh = true;

        if (refresh) {

            this.activeStreams = await this.listCache.fetch('default',
                async () => await this.liveStreamsApi.active().toPromise() as PagedArray<ApiLiveStream>
            );

            if (!this.activeStreams)
                return [];

            this.activeStreams = this.activeStreams.filter(x => x.publish_targets.includes('web'));
            this.lastRefreshed = Date.now();
        }

        return this.activeStreams;
    }

    async get(id: number) {
        if (!id)
            return null;
        let entitled = await this.billing.isEntitled();
        let cacheKey = `stream_${id}_${entitled ? 'premium' : 'public'}`;

        return await this.cache.fetch(
            cacheKey,
            async discard => {
                let stream = await this.liveStreamsApi.get(id).toPromise() as ApiLiveStream;

                // Don't save this to cache if we thought we were entitled but our auth token
                // wasn't valid on the other end.

                if (entitled && !stream.asset) {
                    this.logger.warning(`Entitlement status did not match between TYT.com and TYT Platform while fetching live stream ${id}`);
                    discard();
                }

                return stream;
            }
        );
    }
}